<template>
  <div>
    <router-view></router-view>
    <CRow v-if="!subPage">
      <CCol>
        <CCard>
          <CCardBody>
            <ShowcasePrices />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import ShowcasePrices from "./ShowcasePrices";
// import ProductsTableAva from "./ProductsTableAva"
// import ProductsTableAll from "./ProductsTableAll"

// import ProductsList from "./ProductsList"

export default Vue.extend({
  name: "Products",
  data() {
    return {
      activeTab: 1,
    };
  },
  components: {
    ShowcasePrices,
    // ProductsTableAva,
    // ProductsTableAll,
    // ProductsList
  },
  computed: {
    subPage() {
      return this.$route.params.pp_id;
    },
  },
});
</script>
